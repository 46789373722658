export const SET_BENEFITS = "SET_BENEFITS";
export const SET_TEACHER_REPORT_PREVIEW = "SET_TEACHER_REPORT_PREVIEW";

export const setHeaderBenefits = (benefits) => {
    return {
      type: SET_BENEFITS,
      payload: benefits
    };
  };

export const setTeacherReportPreview = (data) => {
    return {
      type: SET_TEACHER_REPORT_PREVIEW,
      payload: data
    };
  };