import { getAuthenticatedAxios, getDataFromResponse } from "./helpers";

export const CertificateApiEndpoints = {

    GetCourseCertificateDetails: (courseId, subscriptionId) =>
      getAuthenticatedAxios()
        .get(`CourseDetails/GetCourseCertificateDetails/${courseId}/${subscriptionId}`)
        .then(getDataFromResponse),

    CreateCertificateOrderRequest: (data) =>
      getAuthenticatedAxios()
        .post(`CertificateOrderPayment/CreateCertificateOrderRequest`, data)
        .then(getDataFromResponse),

    CertificatePaymentCardRequest: (data) =>
      getAuthenticatedAxios()
        .post(`CertificateOrderPayment/CertificatePaymentCardRequest`, data)
        .then(getDataFromResponse),

    GetTapPaymentDetailsCheckout: id =>
        getAuthenticatedAxios()
            .post(`CertificateOrderPayment/GetTapPaymentDetails?chargeId=${id}`)
            .then(getDataFromResponse),
}