import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-beat.scss";
import "./index.scss";
import { apiBaseUrl } from "../../../../api/helpers";

export const StreachedSearchField = ({history})=>{
  const [searchValue, setSearchValue] = useState("");
  const [fastResults, setFastResults] = useState([]);
  const [loadingFastResults, setLoadingFastResults] = useState(false);
  const handleSubmit = () => {
    history.push(`/search/${searchValue?.length ? searchValue : " "}`);
  };
  const handleSearchInput = (event) => {
    event.key === "Enter" ? handleSubmit() : console.log("auto complete");
  };

  const fastSearchResults = async () => {
    setLoadingFastResults(true)
    const response = await axios({
      method: "get",
      url: `${apiBaseUrl}/Courses/QuickSearchCourse/${searchValue}`,
    });
    setFastResults(response.data?.data);
    setLoadingFastResults(false)
  };

  useEffect(() => {
    if (searchValue.length > 2) {
      fastSearchResults();
    }
  }, [searchValue.length]);
  return(
    <>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle className={"bg-white border-0 p-0"}>
          <div className=" d-flex align-items-center search-input-container ">
            <input
              className="search-box border-0 px-2"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(e) => handleSearchInput(e)}
              placeholder="ابحث عن دورة"
            />
            {/* <div className="d-flex justify-content-end">
              <BsSearch className="main-color" onClick={() => handleSubmit()} />
            </div> */}
            {
              searchValue?.length ? (
              <a href={`/search/${searchValue?.length ? searchValue : " "}`} className="d-flex justify-content-end px-2">
                <BsSearch className="main-color" />
              </a>

              ):(
                <div className="d-flex justify-content-end px-2">
                <BsSearch className="light-sub-color" />
              </div>
              )
            }
          </div>
        </DropdownToggle>
        {searchValue?.length > 2 ? (
          <DropdownMenu className={"overflow-auto"}>
            {loadingFastResults? 
              (
                <div className="d-flex align-items-center p-2">
                  <span className="dark-text">جاري البحث</span>
                  <Loader
                    type="ball-beat"
                    className="dark-loader"
                  />
                </div>
              ):fastResults?.length ? (
              fastResults.map((item) => {
                return (
                  <DropdownItem className="p-0">
                    <a
                      className="nav-link d-inline-block w-100"
                      // onClick={() =>
                      //   history.push(`/course/details/${item?.id}`)
                      // }
                      // to={`/course/details/${item?.id}`}
                      href={`/course/details/${item?.id}`}
                    >
                      {item?.name?.includes("-")? 
                      item?.name?.split("-")[0] + "-" +item?.name?.split("-")[1] + "..."
                        : item?.name}
                      {/* {item?.name?.length > 30
                        ? item?.name?.substring(0, 30) + ".."
                        : item?.name} */}
                    </a>
                  </DropdownItem>
                );
              })
            ) : (
              <DropdownItem className="p-0">
                <a className="nav-link d-inline-block">
                  لا توجد نتائج بحث مشابهة
                </a>
              </DropdownItem>
            )}
          </DropdownMenu>
        ) : null}
      </UncontrolledDropdown>
    </>
  )
}


const SearchBoxComponent = (props) => {

  if(props.isMobileView){
    return(
      <StreachedSearchField history={props.history} />
    )
  }
  else{
    return (
      <>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle className={"bg-white border-0"}>
            {/* <BsSearch className="main-color" onClick={()=>setSearchValue("")} /> */}
            <BsSearch className="main-color" />
          </DropdownToggle>
          <DropdownMenu>
            <StreachedSearchField history={props.history} />
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }

};

export const SearchBox = withRouter(SearchBoxComponent);
