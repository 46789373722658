import { getAuthenticatedAxios, getDataFromResponse } from "./helpers";

export const SubscriptionApiEndpoints = {
  getSubscription: courseId =>
    getAuthenticatedAxios()
      .get(`/content/${courseId}`, {})
      .then(getDataFromResponse),

  getSubscriptionSidebar: (id, type) =>
    getAuthenticatedAxios()
      .get(`/MenuItems/GetMenuItems?id=${id}&typeId=${type}&designVersion=Version1`, {})
      .then(getDataFromResponse)
};
