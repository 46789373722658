export const inputsData = {

    administration_name: {
        name: "اسم الإدارة",
        value: "",
    },
    education_sector: {
        name: "قطاع التعليم",
        value: "",
    },
    office_name: {
        name: "اسم المكتب",
        value: "",
    },
    School_name: {
        name: "اسم المدرسة",
        value: "",
    },

    program_executor: {
        name: "اسم منفذ/ة البرنامج",
        value: "",
    },
    program_executor_Job_Description: {
        name: "الوصف الوظيفي",
        value: "",
    },

    execution_partners: {
        name: "أسماء أو لجان أو جهات شاركت في التنفيذ",
        value: "",
    },
    Program_name: {
        name: "اسم البرنامج",
        value: "",
    },
    Program_specialization: {
        name: 'مجال البرنامج',
        value: "",
    },

    Program_Goals: {
        name: "أهداف البرنامج",
        value: "",
    },
    Program_description: {
        name: "وصف البرنامج" ,
        value: "",
    },

    starting_date: {
        name: "تاريخ التنفيذ",
        value: "",
    },
    implementation_Duration: {
        name: "مدة التنفيذ",
        value: "",
    },

    Beneficiaries: {
        name: "المستفيدون",
        value: "",
    },
    Beneficiaries_number: {
        name: "عدد المستفيدين",
        value: "",
    },

    evidence: [
        {
            image_title: "",
            url: "",
            error: "",
        },
    ],

    teacher_name: {
        name: "اسم المعلم/ة ( المنفذ)",
        value: "",
    },
    School_principal: {
        name: "مدير المدرسة أو المكتب",
        value: "",
    },
}