export const sidebarItems =[
    {
      "titleEnglish": "Schedule",
      "titleArabic": "جدول الدورة",
      "order": 1
    },
    {
      "titleEnglish": "RecordedLectures",
      "titleArabic": "المحاضرات المسجلة",
      "order": 2
    },
    {
      "titleEnglish": "BookletPDF",
      "titleArabic": "الملزمة(PDF)",
      "order": 3
    },
    {
      "titleEnglish": "BookletPrinted",
      "titleArabic": "طلب الملزمة(مطبوعة)",
      "order": 4
    },
    {
      "titleEnglish": "Discussions",
      "titleArabic": "المناقشات",
      "order": 5
    },
    {
      "titleEnglish": "TelegramGroup",
      "titleArabic": "قروب التيليجرام",
      "order": 6
    },
    {
      "titleEnglish": "TelegramChannel",
      "titleArabic": "قناة التيليجرام",
      "order": 7
    },
    {
      "titleEnglish": "Attachments",
      "titleArabic": "مرفقات الدورة",
      "order": 8
    },
    {
      "titleEnglish": "DigitalExams",
      "titleArabic": "الاختبارات الإلكترونية",
      "order": 9
    },
    {
      "titleEnglish": "Practices",
      "titleArabic": "التدريبات",
      "order": 10
    },
    {
      "titleEnglish": "NotCompletedExams",
      "titleArabic": "أسئلة تحتاج للمراجعة",
      "order": 11
    },
    {
      "titleEnglish": "AskQuestions",
      "titleArabic": "اسأل مدرب",
      "order": 12
    }
  ]