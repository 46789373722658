import { GET_COMPETITION_DETAILS, SET_SHADY_TRAINING_ID } from "../actions";

export const categoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COMPETITION_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const shadyExamsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SHADY_TRAINING_ID:
      return {...state, trainingId: action.payload}
    default:
      return state;
  }
};
