import { SET_BENEFITS, SET_TEACHER_REPORT_PREVIEW } from "../actions";
import { inputsData } from "../components/sites-for-teachers/data/inputs-data";

export const headerReducer = (state={allBenefits: [], reportPreviewData: inputsData}, action) =>{
    switch (action.type) {
        case SET_TEACHER_REPORT_PREVIEW:
            return {...state, reportPreviewData: action.payload}

        case SET_BENEFITS:
            return {...state, allBenefits: action.payload}
    
        default:
            return state;
    }

}