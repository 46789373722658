import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import AOS from "aos";
import ReactPlayer from "react-player";


import "./styles.scss"

const FreeLectureLandingPage = () => {

  useEffect(() => {
    AOS.init();
  }, [])
  
  return (
    <>
      <div className='position-relative' style={{overflowX: "hidden", position: "relative"}}>
        <div className='row p-0 mx-0 mt-1'>
          <div className='course-wrapper w-100 h-100 d-flex justify-content-center align-items-center'>
            <div className='course-inner-wrapper'>
              <div className='title-section'>
                <h1 className='title'>المحاضرة المجانية</h1>
                <p className='sub-title d-none d-md-block'>
                  STEP المكثفة للإستعداد للستيب
                </p>
                <p className='sub-title d-md-none'>
                  <span>المكثفـــة للإستعداد</span>
                  <br />
                  <span className='second-span'>للستيب STEP</span>
                </p>
              </div>
              <div className='iframe-wrapper'>
                <ReactPlayer
                  width="100%"
                  height="100%"
                  url="https://www.youtube.com/watch?v=056MkZbkldY"
                  controls="true"
                  // light={true}
                />
              </div>
              <div 
                className='applying-section'
                
              >
                <p>
                  <span>للتسجيل في دورة</span>
                  <br />
                  <span>STEP المكثفة للإستعداد للستيب</span>
                </p>
              
                <a
                  href='https://bit.ly/step-landpage'
                  className="btn w-50"
                >
                  سجل الآن
                </a>{" "}
                {/* <Link
                to={`/course/details/%D8%AF%D9%88%D8%B1%D8%A9_%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B9%D8%AF%D8%A7%D8%AF_%D8%A7%D9%84%D8%B4%D8%A7%D9%85%D9%84_%D9%84%D9%84%D8%B311191`}
                  className="btn w-50"
                >
                  سجل الآن
                </Link>{" "} */}
              </div>
            </div>
          </div>
          <div className='left-background'>
            <img 
              src={process.env.PUBLIC_URL + "/assets/images/Free-Lecture-Landing-Page-Side-bg.png"} 
              alt="Side-bg"
              className=''
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default FreeLectureLandingPage