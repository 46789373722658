import { Api } from "../api";

export const GET_SUBSCRIPTION = "getSubscription";
export const ADD_RATE_VALUE = "addRateValue";
export const SET_EXAMPLER_CHAPTERS = "setExamplerChapters";
export const SET_EXAMPLER_DYNAMIC_SIDEBAR_ITEMS = "setExamplerDynamicItems";
export const GET_SUBSCRIPTION_SIDEBAR = "getSubscriptionSidebarMenu";
export const SET_PDF_CONTROLES = "setPDFControls";
export const SET_SIDEBAR_LOADER = "setSidebarLoader";
export const SET_SHADY_QUESTION_ID = "setShadyQuestionId";
export const SET_SHADY_EXAM_ID = "setShadyExamId";
export const SET_SHADY_TRAINING_ID = "setShadyTrainingId";
export const SET_COURSE_CONTENT = "setcourseContnet";

export const getSubscription = (courseId) => {
  return {
    type: GET_SUBSCRIPTION,
    payload: Api.subscription.getSubscription(courseId),
  };
};

export const addRateValue = (data) => {
  return {
    type: ADD_RATE_VALUE,
    payload: data,
  };
};

export const setExamplerChapters = (data) => {
  return {
    type: SET_EXAMPLER_CHAPTERS,
    payload: data,
  };
};

export const setExamplerDynamicItems = (data) => {
  return {
    type: SET_EXAMPLER_DYNAMIC_SIDEBAR_ITEMS,
    payload: data,
  };
};

export const setPDFControls = (PDFControls) => {
  return {
    type: SET_PDF_CONTROLES,
    payload: PDFControls,
  };
};

export const getsubscriptionSidebarMenu = (courseId, type) => {
  debugger;
  return {
    type: GET_SUBSCRIPTION_SIDEBAR,
    payload: Api.subscription.getSubscriptionSidebar(courseId, type),
  };
};

export const setSidebarLoading = () => {
  return {
    type: SET_SIDEBAR_LOADER,
    payload: true,
  };
};

export const setShadyQuestionId = (id) => {
  return {
    type: SET_SHADY_QUESTION_ID,
    payload: id,
  };
};

export const setShadyExamId = (id) => {
  return {
    type: SET_SHADY_EXAM_ID,
    payload: id,
  };
};

export const setShadyTrainingId = (id) => {
  return {
    type: SET_SHADY_TRAINING_ID,
    payload: id,
  };
};

export const setcourseContnet= (details) => {
  return {
    type: SET_COURSE_CONTENT,
    payload: details,
  };
};
