import { Api } from "../api";

export const GET_COMPETITION_DETAILS = "getCompetitionDetails";
export const SET_SHADY_TRAINING_ID = "setShadyTrainingId";

export const getCompetitionDetails = competitionId => {
  return {
    type: GET_COMPETITION_DETAILS,
    payload: Api.categories.getCompetitionDetails(competitionId)
  };
};

export const setShadyTrainingId = id => {
  return {
    type: SET_SHADY_TRAINING_ID,
    payload: id
  };
};
