import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerElem: null,
    };
    this.findHeaderElem = this.findHeaderElem.bind(this);
  }

  findHeaderElem() {
    const _headerEleme = document.getElementById("header-nav");
    this.setState({ ...this.state, headerElem: _headerEleme });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const path = this.props.location.pathname;
      if(!path.startsWith("/course/content/")){
        window.scrollTo(0, 0);
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
    this.findHeaderElem();
    }, 500);
  }

  render() {
    const path = this.props.location.pathname;
    const paddingTopClass = (
      path!=='/free-lecture-landing-page' && 
      !path.startsWith("/courses-campaign-pages") &&
      !path.startsWith("/slideShow/")
      ) ? "paddingTopClass" : ""
    return (
      <div className={paddingTopClass}>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(ScrollToTop);
